<template>
  <div class="opageBox">
    <div>
      <headGoBack />
      <div class="headBoxImg">
        <img
          src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/649d13ef1a41a.png"
          alt=""
        />
      </div>
      <div class="conBox">
        <h1>
          {{ questionnaireInfo.survey_title }}
        </h1>
        <div class="tipBox">
          <p>
            {{ questionnaireInfo.survey_desc }}
          </p>
        </div>
        <div class="surveyList">
          <div
            class="surveyOpt"
            v-for="(item, index) in questionnaireInfo.detail"
            :key="index"
          >
            <div class="surveyTitle">
              <div class="questionnaireTopic">
                {{ index + 1 }}、{{ item.title }}
              </div>
              <div v-if="item.type == 1" class="multipleChoiceHint">
                *複数選択可能
              </div>
            </div>
            <!-- 单选类型问题 -->
            <div class="radioBox" v-if="item.type == 0">
              <div
                class="radioOpt"
                v-for="(optionItem, optionIndex) in item.options"
                :key="optionIndex"
                @click="item.checkedId = optionItem.id"
              >
                <div
                  class="iconJudgment"
                  :class="{ active: item.checkedId == optionItem.id }"
                ></div>
                <span>{{ optionItem.text }}</span>
                <input
                  v-if="optionItem.hasInput == true"
                  v-model="optionItem.inputValue"
                  @click.stop
                />
              </div>
            </div>
            <!-- 多选类型问题 -->
            <div class="radioBox" v-else-if="item.type == 1">
              <div
                class="radioOpt"
                v-for="(optionItem, optionIndex) in item.options"
                :key="optionIndex"
                @click="
                  () => {
                    let jieguo = item.checkedList.findIndex((ee) => {
                      return ee == optionItem.text;
                    });
                    if (jieguo != -1) {
                      item.checkedList.splice(jieguo, 1);
                    } else {
                      item.checkedList.push(optionItem.text);
                    }
                  }
                "
              >
                <div
                  class="iconJudgment"
                  :class="{
                    active: item.checkedList.find((ee) => {
                      return ee == optionItem.text;
                    }),
                  }"
                ></div>
                <span>{{ optionItem.text }}</span>
                <input
                  v-if="optionItem.hasInput == true"
                  v-model="optionItem.inputValue"
                  @click.stop
                />
              </div>
            </div>
            <!-- 输入框类型问题 -->
            <div class="inputBox" v-else>
              <textarea v-autoHeightForLineBreak v-model="item.inputValue" />
            </div>
            <div class="surveyText">必須</div>
          </div>
        </div>
        <button class="submitBtn" @click="questionnaireSubmit()">
          {{ $fanyi("提交") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import "vant/lib/index.css";
import headGoBack from "../../components/headGoBack";
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
const questionnaireInfo = ref({});
//------------------------ methods -------------------------------------
const getData = () => {
  proxy.$api
    .questionnaireDetail({
      questionnaire_id: proxy.$route.query.id,
    })
    .then((res) => {
      res.data.detail.forEach((item) => {
        if (item.type == 0) {
          item.checkedId = 0;
        } else if (item.type == 1) {
          item.checkedList = [];
        } else {
          item.inputValue = "";
        }
        item.options.forEach((optionsItem, optionIndex) => {
          optionsItem.inputValue = "";
          optionsItem.id = optionIndex;
        });
      });
      questionnaireInfo.value = res.data;
    });
};
//试卷提交
const questionnaireSubmit = () => {
  let status = false;
  let arr = [];
  let data = {
    questionnaire_id: proxy.$route.query.id,
    questionnaire_detail: arr,
  };
  for (let i = 0; i < questionnaireInfo.value.detail.length; i++) {
    if (
      (questionnaireInfo.value.detail[i].type == 1 &&
        questionnaireInfo.value.detail[i].checkedList.length === 0) ||
      (questionnaireInfo.value.detail[i].type == 0 &&
        questionnaireInfo.value.detail[i].checkedId === "") ||
      (questionnaireInfo.value.detail[i].type == 2 &&
        questionnaireInfo.value.detail[i].inputValue === "")
    ) {
      status = true;
      break;
    } else {
      if (questionnaireInfo.value.detail[i].type == 1) {
        let checkedList = [];
        //循环选项数组和选中的数组
        questionnaireInfo.value.detail[i].options.forEach((optionsItem) => {
          questionnaireInfo.value.detail[i].checkedList.forEach(
            (checkedItem) => {
              //判断可选项数组的文本与已选中的文本
              if (checkedItem === optionsItem.text) {
                if (optionsItem.hasInput === true) {
                  checkedList.push(optionsItem.inputValue);
                } else {
                  checkedList.push(optionsItem.text);
                }
              }
            }
          );
        });
        arr.push({
          id: questionnaireInfo.value.detail[i].id,
          answer: checkedList,
        });
      } else if (questionnaireInfo.value.detail[i].type == 0) {
        arr.push({
          id: questionnaireInfo.value.detail[i].id,
          //判断单选框是否支持输入，支持输入就追加输入的值，否则返回文本
          answer:
            questionnaireInfo.value.detail[i].options[
              questionnaireInfo.value.detail[i].checkedId
            ].hasInput == false
              ? [
                  questionnaireInfo.value.detail[i].options[
                    questionnaireInfo.value.detail[i].checkedId
                  ].text,
                ]
              : [
                  questionnaireInfo.value.detail[i].options[
                    questionnaireInfo.value.detail[i].checkedId
                  ].inputValue,
                ],
        });
      } else {
        arr.push({
          id: questionnaireInfo.value.detail[i].id,
          answer: [questionnaireInfo.value.detail[i].inputValue],
        });
      }
    }
  }
  if (status === true) {
    proxy.$message.error(proxy.$fanyi("请填写必填信息"));
    return;
  }
  data.questionnaire_detail = JSON.stringify(data.questionnaire_detail);
  proxy.$api.questionnaireSubmit(data).then((res) => {
    if (res.code != 0) return proxy.$toast(proxy.$fanyi(res.msg));
    proxy.$toast(proxy.$fanyi("感谢您的填写"));
    setTimeout(() => {
      proxy.$fun.closeWindow();
    }, 1500);
  });
};
//------------------------ pageLoad -------------------------------------
getData();
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.opageBox {
  min-height: 100vh;
  background-color: #f6f6f6;
  .headBoxImg {
    img {
      height: 240px;
      width: 100%;
      margin-bottom: -80px;
    }
  }
  .conBox {
    background-color: white;
    padding: 60px 30px;
    margin: 0 30px;
    z-index: 2;
    position: relative;
    h1 {
      line-height: 64px;
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 25px;
    }
    .tipBox {
      margin-bottom: 50px;
      p {
        font-size: 24px;
        color: #999999;
        line-height: 36px;
      }
    }
    .surveyList {
      margin-bottom: 60px;
      .surveyOpt {
        margin-bottom: 30px;
        .surveyTitle {
          margin-bottom: 22px;
          .questionnaireTopic {
            display: inline-block;
            font-size: 28px;
            font-weight: bold;
            line-height: 42px;
          }
          .multipleChoiceHint {
            display: inline-block;
            line-height: 32px;
            font-size: 24px;
            color: #ff0000;
          }
        }
        .radioBox {
          margin-bottom: 10px;
          .radioOpt {
            display: flex;
            align-items: center;
            padding: 10px 0;
            .iconJudgment {
              width: 32px;
              height: 32px;
              border: 1px solid #dcdfe6;
              border-radius: 50%;
              margin-right: 12px;
            }
            .iconJudgment.active {
              border: 10px solid #b4272c;
            }
            span {
              font-size: 24px;
              line-height: 36px;
            }
            input {
              width: 240px;
              height: 48px;
              border: 1px solid #dcdfe6;
              margin-left: 15px;
              border-radius: 8px;
              transition: 0.1s;
              &:focus {
                border-color: #b4272c;
              }
            }
          }
        }
        .inputBox {
          margin-bottom: 10px;
          textarea {
            width: 100%;
            height: 60px;
            padding: 12px 20px;
            background: #ffffff;
            border: 1px solid #dcdfe6;
            border-radius: 8px;
            font-size: 24px;
            line-height: 36px;
            color: #333333;
          }
        }
        .surveyText {
          line-height: 32px;
          color: #ff0000;
          font-size: 24px;
        }
      }
    }
    .submitBtn {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 64px;
      background: #b4272c;
      border-radius: 6px;
      padding: 20px 36px;
      font-size: 24px;
      font-weight: bold;
      color: #ffffff;
      line-height: 30px;
    }
  }
}
</style>
